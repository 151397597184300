<template>
  <div class="interact">
    <div class="interact_banner_wraper">
      <div class="interact_banner_container">
        <div class="interact_banner_container_title">互动交流解决方案</div>
        <div class="interact_banner_container_desc">文件信息线上共享</div>
        <div class="interact_banner_container_detail">
          把跑腿的事情放心交给系统
        </div>
        <div class="interact_banner_container_btn">在线咨询</div>
      </div>
    </div>

    <div class="interact_user">
      <div class="interact_user_title">用户痛点</div>
      <div class="interact_user_item">
        <div class="interact_user_item_left">
          <div class="interact_user_left_img">
            <img src="@/assets/images/solve/interact/img1.png" alt="" />
            <div class="interact_user_left_li">
              <div class="interact_user_left_li_title">不能跨单位转发</div>
              <div class="interact_user_left_li_desc">
                不同单位之间，便函不能相互转发
              </div>
            </div>
          </div>
          <div class="interact_user_left_img">
            <img src="@/assets/images/solve/interact/img2.png" alt="" />
            <div class="interact_user_left_li">
              <div class="interact_user_left_li_title">操作模式不灵活</div>
              <div class="interact_user_left_li_desc">
                不能批量上传附件，<br />
                无法储存便函草稿，追回便函不便
              </div>
            </div>
          </div>
        </div>
        <div class="interact_user_item_middle">
          <img src="@/assets/images/solve/interact/user.png" alt="" />
        </div>
        <div class="interact_user_item_right">
          <div class="interact_user_left_img">
            <img src="@/assets/images/solve/interact/img3.png" alt="" />
            <div class="interact_user_left_li">
              <div class="interact_user_left_li_title">安全规范性差</div>
              <div class="interact_user_left_li_desc">
                使用QQ、微信传输文件，<br />
                不规范且不安全
              </div>
            </div>
          </div>
          <div class="interact_user_left_img">
            <img src="@/assets/images/solve/interact/img4.png" alt="" />
            <div class="interact_user_left_li">
              <div class="interact_user_left_li_title">时效性差</div>
              <div class="interact_user_left_li_desc">
                审批流程繁琐，时效性差，<br />
                且无法跟踪进度
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="interact_case_wraper">
      <div class="interact_case">
        <div class="interact_case_title">解决方案</div>
        <div class="interact_case_item">
          <div class="interact_case_item_left">
            <img src="@/assets/images/solve/interact/img5.png" alt="" />
          </div>
          <div class="interact_case_item_right">
            <div
              class="interact_case_item_li"
              v-for="(item, index) in list"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <div class="interact_case_item_li_title">{{ item.title }}</div>
              <div class="interact_case_item_li_desc" v-html="item.desc"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="interact_img_wraper">
      <div class="interact_img">
        <div class="interact_img_item img1">
          <img src="@/assets/images/solve/interact/img9.png" alt="" />
          <div class="interact_img_item_title">便函转发</div>
        </div>
        <div class="interact_img_item img2">
          <img src="@/assets/images/solve/interact/img8.png" alt="" />
          <div class="interact_img_item_title">查阅跟踪</div>
        </div>
      </div>
    </div>
    <div class="interact_customer">
      <div class="interact_customer_title">客户案例</div>
      <div class="interact_customer_bg"></div>
      <div class="interact_customer_item">
        <div class="interact_customer_item_info">
          在佛山市高明区，9000多名用户告别了效率低下的纸笔办公模式，使用互动交流功能发送、接收、回复便函，每天有1000多份便函快速安全地在系统内流转。便函功能这一使用，极大提升了办公人员的办文效率，让复杂的流程变得简单化、规范化、人性化。
        </div>
      </div>
    </div>
    <solve-footer />
  </div>
</template>

<script>
import $ from 'jquery'
import SolveFooter from '@/components/SolveFooter'
export default {
  name: '',
  components: {
    SolveFooter
  },
  props: {

  },
  data () {
    return {
      list: [
        {
          img: require('@/assets/images/solve/interact/icon1.png'),
          title: '全网使用',
          desc: '在同一张网上实现信息文件发起、<br/>接收、转发、回复等功能'
        },
        {
          img: require('@/assets/images/solve/interact/icon2.png'),
          title: '操作简便',
          desc: '支持批量上传附件，可追回便函、<br/>起草便函，让便函管理更人性化'
        },
        {
          img: require('@/assets/images/solve/interact/icon3.png'),
          title: '流程跟踪',
          desc: '实时查看便函节点状态，自动短信提醒<br/>办公人员处理便函，有效提高审批效率'
        },
        {
          img: require('@/assets/images/solve/interact/icon4.png'),
          title: '安全性高',
          desc: '所有文件信息在同一张网上交流，<br/>无惧信息泄露，保密性高'
        }
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {
    window.addEventListener('scroll', this.slide)
    setTimeout(() => {
      $('.interact_user_item_left').addClass('slide')
      $('.interact_user_item_right').addClass('slide')
    }, 100)
  },
  destroyed () {
    window.removeEventListener('scroll', this.slide)
  },
  methods: {
    slide () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      const targetHeight = $('.interact_img_wraper').offset().top
      if (h > targetHeight - 300) {
        $('.interact_img_item').addClass('animation')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
